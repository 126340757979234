::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #F5F5F5;

  border-radius: 10px;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background-color: #F5F5F5;
}

::-webkit-scrollbar-thumb {
  background-color: #3f87f5;
  border: none;
  border-radius: 10px;
}

body, html {
  height: 100%;
  overflow-x: hidden;
}

.container {
  max-width: 1750px;
  width: 100%;

  margin: 0 auto;
}

* {
  outline: none !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.apexcharts-legend-text {
  font-size: 15px !important;

  @media screen and (max-width: 991px) {
    font-size: 13px !important;
  }

  @media screen and (max-width: 767px) {
    font-size: 12px !important;
  }
}

.password {
  position: relative;
}

textarea.ant-input {
  resize: none;
}

.angular-editor-textarea {
  min-height: 200px !important;
  font-size: 14px;
}

#insertImage-,
#insertVideo-,
#insertHorizontalRule-,
#clearFormatting-,
#toggleEditorMode- {
  display: none;
}

pre code,
.ae-font .ae-picker-label,
.apexcharts-text tspan,
.apexcharts-canvas * {
  font-family: 'Jost', sans-serif !important;
}

.password-control {
  position: absolute;
  top: 50%;
  right: 12px;
  display: inline-block;
  width: 18px;
  height: 18px;
  transform: translateY(-50%);
  background: url('../img/icons/view.svg') 0 0 no-repeat;
  cursor: pointer;
}

.password-control.view {
  background: url('../img/icons/no-view.svg') 0 0 no-repeat;
}

.pass_error {
  display: none;
  color: red;
}

.form_error {
  color: red;
  font-size: 13px;
  line-height: 1;

  position: absolute;
  left: 0;
  bottom: -13px;
}

.iti {
  width: 100%;
}

.country-dropdown {
  width: 260px !important;
  font-size: 12px !important;
}

.highcharts-credits,
.ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
  display: none;
}

.h {
  &-0 {
    height: 0 !important;
    min-height: 0 !important;
    padding: 0;
  }
}

.collapse-text {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;

  &_3 {
    -webkit-line-clamp: 3;
  }
}

.text {
  &-green {
    color: #03A66D;
  }

  &-red {
    color: #CF304AFF;
  }

  &-black {
    color: #111;
  }

  &-center {
    text-align: center;
  }

  &-wrap {
    word-wrap: break-word;
    word-break: break-word;
  }

  &-dashed {
    text-decoration: dashed;
  }

  &-decoration-underline {
    text-decoration: underline;
  }

  &-normal {
    font-weight: 400;
  }

  &-semibold {
    font-weight: 600;
  }

  &-medium {
    font-weight: 500;
  }
}

.cursor {
  cursor: pointer;
}

.th-hide {
  position: relative;

  &::before {
    content: '';
    display: block;
    width: calc(100% + 10px);
    height: calc(100% + 10px);
    position: absolute;
    left: 50%;
    top: 50%;

    z-index: 10;

    transform: translate(-50%, -50%);
  }

  & .ant-table-column-sorter {
    display: none;

    &::before {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
    }
  }
}

.logo {
  display: flex;
  justify-content: center;
  align-items: center;

  & img {
    max-height: 60px;
  }
}

.ant-table {
  font-size: 15px !important;
}

.error__block {
  color: #de4436;
  background: rgba(222, 68, 54, .05);
  border-color: transparent;
  border-radius: 10px;
  font-size: 14px;
  font-weight: 500;

  padding: 10px;
}

.hover-button {
  padding: 5px 15px;
  font-size: 18px;
  color: #53535f;
  display: block;
  transition: background-color .3s;
  -webkit-transition: background-color .3s;
  -moz-transition: background-color .3s;
  -o-transition: background-color .3s;
  -ms-transition: background-color .3s;
  border-radius: 0.25rem;

  //min-width: 48px;
  //min-height: 48px;
  //line-height: 48px;
  text-align: center;

  border: none;
  background: transparent;

  &:hover {
    color: #3f87f5;
    background-color: #3f87f51a;
  }

  &_cancel {
    color: #de4436;
    background: rgba(222, 68, 54, .05);

    &:hover {
      color: #de4436;
      background: rgba(222, 68, 54, .05);
    }
  }

  &_save {
    color: #3f87f5;
    background: rgba(63, 135, 245, .1);

    &:hover {
      color: #3f87f5;
      background: rgba(63, 135, 245, .1);
    }
  }

  &_link {
    color: #52c41a;
    background: rgba(82, 196, 26, .1);

    &:hover {
      color: #52c41a;
      background: rgba(82, 196, 26, .1);
    }
  }
}

.m {
  &-t {
    &--10 {
      margin-top: -10px !important;
    }
  }
}

.background {
  &-yellow {
    background-color: rgba(255, 193, 7, 0.1);
  }
}

.icon {
  &__pin {
    position: absolute;
    right: 14px;
    top: 17px;
  }
}

.content {
  & img {
    display: block;
    max-width: 100%;
  }
}

.tooltip-comment {
  & font {
    font-family: unset !important;
  }

  & img {
    display: none !important;
  }
}

.ant-tooltip-inner {
  & p {
    color: #fff;
  }
}

.csv-modal {
  max-width: 1250px !important;
  width: 100% !important;
}

.ant-picker {
  &.ng-invalid.ng-touched {
    background: rgba(255, 0, 0, 0.09);
    border: 1px solid #FF0000;
  }
}

.ant-btn-circle {
  display: flex;
  align-items: center;
  justify-content: center;
}

ae-select[ng-reflect-model="Times New Roman"] {
  display: none !important;
}

font {
  font-weight: 400;
  font-size: unset;
}

.content {
  & ul {
    padding-left: 0;

    @media screen and (max-width: 991px) {
      padding-left: 0;
    }
  }
}

.select-height {
  & .ant-select-selector {
    height: auto !important;
  }
}

.comments__list {
  & .ant-list-item {
    padding: 12px;
  }
}

.statistics {
  &__list {
    max-width: 1300px;
    width: 100%;

    margin: 0 auto;
  }
}

.filters {

  &:not(.filters_leads) {
    @media (min-width: 992px) {
      .col-lg-3 {
        flex: 0 0 20%;
        max-width: 20%;
      }
    }
  }

  @media screen and (max-width: 767px) {
    & .col-lg-3 {
      &:not(:last-of-type) {
        margin-bottom: 15px;
      }
    }
  }

  & .col-ld-20 {
    flex: 0 0 20%;
    max-width: 20%;

    @media screen and (max-width: 1199px) {
      flex: 0 0 50%;
      max-width: 50%;

      margin-bottom: 15px;
    }

    @media screen and (max-width: 555px) {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
}

// new style

.was-online {
  position: relative;
  top: -10px;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  display: inline-block;
  background-color: #3cd911;
  margin-right: 20px;
}

a {
  color: #333;

  &:hover {
    color: #333;
  }
}

// table

.scroll-table {
  &__pagination {
    position: relative;
    top: -28px;
  }

  &__table {
    margin-left: -1.5rem;
    margin-right: -1.5rem;
    padding: 0 0 0 1.5rem;

    @media screen and (max-width: 991px) {
      margin-left: -15px;
      margin-right: -15px;

      padding-left: 15px;
    }

    & .ant-pagination {
      display: flex;
      align-items: center;

      margin-right: 1.5rem !important;
    }

    & .ant-table-content {
      overflow-x: auto;
    }

    & .ant-pagination {
      margin-bottom: 0 !important;
      position: relative;
      z-index: 2;
    }
  }
}


// backgrounds tr

.tr {
  &-yellow {
    background-color: #fff5c2 !important;

    &:hover {
      & > td {
        background-color: transparent !important;
      }
    }
  }

  &-grey {
    background-color: #ededed !important;

    &:hover {
      & > td {
        background-color: transparent !important;
      }
    }
  }

  &-grey1 {
    background-color: rgb(235, 242, 248) !important;

    &:hover {
      & > td {
        background-color: transparent !important;
      }
    }
  }

  &-purple {
    background-color: #ecd7f8 !important;

    &:hover {
      & > td {
        background-color: transparent !important;
      }
    }
  }

  &-blue {
    background-color: rgba(30, 178, 243, 0.1) !important;

    &:hover {
      & > td {
        background-color: transparent !important;
      }
    }
  }

  &-green {
    background-color: rgba(154, 255, 107, 0.52) !important;

    &:hover {
      & > td {
        background-color: transparent !important;
      }
    }
  }

  &-red {
    background-color: rgb(251, 107, 93, .39) !important;

    &:hover {
      & > td {
        background-color: transparent !important;
      }
    }
  }
}

.wallet {
  &__wrap {
    width: calc(100% - 70px);
  }

  &__add {
    & i {
      font-size: 40px;
      margin-bottom: 5px;
    }
  }
}

.documents {
  &__img {
    display: block;
    width: 65px;
    height: 65px;

    object-position: center;
    object-fit: cover;
  }
}

.upload {
  &__list {
    display: flex;
    margin-left: -4px;
    margin-right: -4px;

    list-style: none;

    & > li {
      width: 25%;
      padding: 0 4px;
      margin-bottom: 8px;

      @media (max-width: 500px) {
        width: 33.333%;
      }

      @media (max-width: 360px) {
        width: 50%;
      }
    }
  }

  &__item {
    padding: 8px;
    border: 1px solid #dedede;
  }

  &__photo {
    display: block;
    width: 100%;
    height: 0;
    padding-top: 100%;

    position: relative;

    & img {
      display: block;
      width: 100%;
      height: 100%;

      position: absolute;
      left: 0;
      top: 0;

      object-position: center;
      object-fit: cover;
    }
  }
}

// widget

.fixed-widgets {
  position: fixed;
  right: 32px;
  bottom: 102px;
  z-index: 99999999;
}
